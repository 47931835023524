import * as React from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import {
  UseFormRegister,
  UseFormGetValues,
  UseFormSetValue,
} from 'react-hook-form'
import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'
import { Swiper } from 'swiper/types'
import { register as swiperRegister } from 'swiper/element/bundle'
import { ScrollMenu } from 'react-horizontal-scrolling-menu'

import { Checkbox } from '~/atoms/search/CheckBox'
import { InputText } from '~/atoms/form/InputText'
import { SearchConditionPrefecture } from '~/organisms/search/SearchConditionPrefecture'
import { SearchConditionArea } from '~/organisms/search/SearchConditionArea'
import { isMobileDisplay } from '@/utils/devise'
import { buildSearchQeuryString } from '@/utils/search'
import { SubmitSwiper } from '@/components/atoms/search/SubmitSwiper'

// images
import CloseIcon from './icon_close.svg?reactCompornt'
import CheckIcon from './icon_check.svg?reactCompornt'
import MakerIcon from './icon_marker.svg?reactCompornt'
import ChevronRightIcon from './icon_chevron_right.svg?reactCompornt'

import * as Style from './style.css'

interface ISelectedGeocodes {
  [key: number]: IGeocode[]
}
interface ISelectedStations {
  [key: number]: IStation[]
}

export interface IProps {
  submitLabel: string
  currentPrefecture?: IPrefecture
  conditionModalDisplay?: boolean
  prefectureModalDisplay?: boolean
  prefectures: [string, string][]
  selectedGeocodes: ISelectedGeocodes
  selectedStations: ISelectedStations
  selectedSearchType: ISearchType
  selectedReserveType: IReserveType
  resetMessage?: boolean
  enableHousekeepingSubsidy?: boolean
  setSwiper: (swiper: Swiper) => void
  setSubmitSwiper: (swiper: Swiper) => void
  register: UseFormRegister<ISearchConditionState>
  getValues: UseFormGetValues<ISearchConditionState>
  setValue: UseFormSetValue<ISearchConditionState>
  resetHandler: () => void
  changeSearchTypeHandler: (searchType: ISearchType) => void
  openPrefectureHandler: () => void
  closePrefectureHandler: () => void
  openAreaSelectHandler: (prefectureId: number, prefectureNmae: string) => void
  closeAreaSelectHandler: () => void
  resetPrefectureHandler: () => void
  transitionEndHandler: (activeIndex: number) => void
  submitHandler: () => void
  closeHandler: () => void
  submitSearchTopHandler: () => void
}

const isRegularSearch = (reserveType: IReserveType) => reserveType === 'regular'

const isSitterOrDoulaSearch = (searchType: ISearchType) => {
  return searchType === 'sitter' || searchType === 'doula'
}

export const makeSeachConditionAreaSummaryText = (
  prefectures: [string, string][],
  selectedGeocodes: ISelectedGeocodes,
  selectedStations: ISelectedStations,
) => {
  const selectedAreaLabels = prefectures.reduce<string[]>(
    (prev, prefecture) => {
      const geocodeLabel = selectedGeocodes[Number.parseInt(prefecture[1], 10)]
        ?.map((s) => s.name)
        .join(', ')
      const stationLabel = selectedStations[Number.parseInt(prefecture[1], 10)]
        ?.map((s) => s.name)
        .join(', ')
      const tmp = [
        prefecture[0],
        [geocodeLabel, stationLabel].filter((label) => !!label).join(', '),
      ]
      return !!tmp[1] ? [...prev, `${tmp[0]}${tmp[1]}`] : prev
    },
    [],
  )
  return selectedAreaLabels.length
    ? selectedAreaLabels.join(', ')
    : 'すべての地域'
}

export const makeSearchConditionSummaryText = (
  search: INomalSearchCondition,
  regularSearch: IRegularSearchCondition,
  prefectures: [string, string][],
  selectedGeocodes: ISelectedGeocodes,
  selectedStations: ISelectedStations,
  reserveType: IReserveType,
  t: TFunction,
) => {
  const labels: string[] = []
  const nurseryAgeLabels: string[] = []
  const areaLabel = makeSeachConditionAreaSummaryText(
    prefectures,
    selectedGeocodes,
    selectedStations,
  )
  if (search.search_type === 'sitter') {
    if (search.nursery_age0_0) {
      nurseryAgeLabels.push(t('search:params.search.nurseryAge0-0'))
    }
    if (search.nursery_age0_1to2) {
      nurseryAgeLabels.push(t('search:params.search.nurseryAge0-1to2'))
    }
    if (search.nursery_age0_3to5) {
      nurseryAgeLabels.push(t('search:params.search.nurseryAge0-3to5'))
    }
    if (search.nursery_age0_6to11) {
      nurseryAgeLabels.push(t('search:params.search.nurseryAge0-6to11'))
    }
    if (search.nursery_age1) {
      nurseryAgeLabels.push(t('search:params.search.nurseryAge1'))
    }
    if (search.nursery_age2) {
      nurseryAgeLabels.push(t('search:params.search.nurseryAge2'))
    }
    if (search.nursery_age3to6) {
      nurseryAgeLabels.push(t('search:params.search.nurseryAge3to6'))
    }
    if (search.nursery_age7to12) {
      nurseryAgeLabels.push(t('search:params.search.nurseryAge7to12'))
    }
    if (nurseryAgeLabels.length) {
      labels.push(nurseryAgeLabels.join(', '))
    }
  }
  if (!!areaLabel) {
    labels.push(areaLabel)
  }
  switch (reserveType) {
    case 'normal':
      labels.push(t('search:params.search.reserveType.normal'))
      break
    case 'regular':
      const weeks: string[] = []
      if (regularSearch?.sunday) {
        weeks.push(t('search:params.regularSearch.sunday'))
      }
      if (regularSearch?.monday) {
        weeks.push(t('search:params.regularSearch.monday'))
      }
      if (regularSearch?.tuesday) {
        weeks.push(t('search:params.regularSearch.tuesday'))
      }
      if (regularSearch?.wednesday) {
        weeks.push(t('search:params.regularSearch.wednesday'))
      }
      if (regularSearch?.thursday) {
        weeks.push(t('search:params.regularSearch.thursday'))
      }
      if (regularSearch?.friday) {
        weeks.push(t('search:params.regularSearch.friday'))
      }
      if (regularSearch?.saturday) {
        weeks.push(t('search:params.regularSearch.saturday'))
      }
      if (weeks.length) {
        labels.push(
          `${
            t('search:params.search.reserveType.regular') as string
          }(${weeks.join(', ')})`,
        )
      } else {
        labels.push(t('search:params.search.reserveType.regular'))
      }
      break
  }

  // その他の検索項目のカウント
  // 下記を含むkeyはカウントしない
  const ignoreCountParamKeys = [
    'search_type',
    'available_schedule',
    'regular_search',
    'work_date',
    'work_time',
    'nursery_age',
    'geocode',
    'station_group_code',
    'order',
    'offset',
  ]
  const params = buildSearchQeuryString({
    reserveType,
    search,
    regular_search: regularSearch,
  }).split('&')
  const otherParams = params.filter(
    (s) => !ignoreCountParamKeys.some((key) => s.includes(key)),
  )
  if (otherParams.length > 0) {
    labels.push(`他${otherParams.length}件`)
  }

  return labels.join(', ')
}

export const SearchCondition = ({
  submitLabel,
  currentPrefecture,
  conditionModalDisplay,
  prefectureModalDisplay,
  prefectures,
  selectedGeocodes,
  selectedStations,
  selectedSearchType,
  selectedReserveType,
  resetMessage,
  enableHousekeepingSubsidy,
  setSwiper,
  setSubmitSwiper,
  register,
  getValues,
  setValue,
  resetHandler,
  changeSearchTypeHandler,
  openPrefectureHandler,
  closePrefectureHandler,
  openAreaSelectHandler,
  closeAreaSelectHandler,
  resetPrefectureHandler,
  transitionEndHandler,
  submitHandler,
  closeHandler,
  submitSearchTopHandler,
}: IProps): JSX.Element => {
  const { t } = useTranslation()
  const swiperRef = React.useRef<SwiperRef>(undefined)
  const wrapperElement = React.useRef<HTMLDivElement>(null)
  const rects = wrapperElement.current?.getBoundingClientRect()
  const isMobile = isMobileDisplay()

  let wrapperRight = 0
  if (rects) {
    wrapperRight = rects.right
  }

  React.useEffect(() => {
    // Register Swiper web component
    swiperRegister()

    // Add event listener
    swiperRef.current.addEventListener(
      'swiperslidechange',
      (event: CustomEvent<[swiper: Swiper]>) => {
        setSwiper(event.detail[0])
      },
    )
    swiperRef.current.addEventListener(
      'swiperinit',
      (event: CustomEvent<[swiper: Swiper]>) => {
        setSwiper(event.detail[0])
      },
    )
    swiperRef.current.addEventListener(
      'swiperslidechangetransitionend',
      (event: CustomEvent<[swiper: Swiper]>) => {
        setSwiper(event.detail[0])
        // lintだとswiperの型が認識できないためチェックを無効にする
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        transitionEndHandler(event.detail[0].activeIndex as number)
      },
    )

    // initialize swiper
    swiperRef.current.initialize()
  }, [])

  const selectedAreaLabel = makeSeachConditionAreaSummaryText(
    prefectures,
    selectedGeocodes,
    selectedStations,
  )

  const showSubmitButton = (() => {
    const geocodesCount: number = Object.values(
      selectedGeocodes,
    ).reduce<number>((total, v: string[]) => total + (v?.length || 0), 0)
    const stationsCount: number = Object.values(
      selectedStations,
    ).reduce<number>((total, v: string[]) => total + (v?.length || 0), 0)
    return geocodesCount + stationsCount > 0
  })()

  return (
    <div className={Style.SearchCondition} ref={wrapperElement}>
      <swiper-container
        init={false}
        initial-slide={0}
        slides-per-view={1}
        allow-touch-move={false}
        ref={swiperRef}
        nested={true}
        style={{ height: '100%' }}
      >
        <swiper-slide>
          {conditionModalDisplay && (
            <>
              <div className={Style.SearchConditionFilter}>
                <div className={Style.SearchConditionFilterHeader}>
                  絞り込み検索
                  <CloseIcon
                    className={Style.SearchConditionFilterHeaderIcon}
                    onClick={closeHandler}
                  />
                </div>
                <div className={Style.SearchConditionFilterRequestTypes}>
                  <ScrollMenu>
                    <span className={Style.SearchConditionFilterRequestType}>
                      <input
                        className={Style.SearchConditionFilterRequestTypeCtrl}
                        type="radio"
                        {...register('search.search_type')}
                        value="sitter"
                        id="searchTypeSitter"
                        onChange={() => changeSearchTypeHandler('sitter')}
                      />
                      <label
                        className={Style.SearchConditionFilterRequestTypeLabel}
                        htmlFor="searchTypeSitter"
                      >
                        {t('search:params.search.searchType.sitter')}
                      </label>
                    </span>
                    <span className={Style.SearchConditionFilterRequestType}>
                      <input
                        className={Style.SearchConditionFilterRequestTypeCtrl}
                        type="radio"
                        {...register('search.search_type')}
                        value="housekeeping"
                        id="searchTypeHousekeeping"
                        onChange={() => changeSearchTypeHandler('housekeeping')}
                      />
                      <label
                        className={Style.SearchConditionFilterRequestTypeLabel}
                        htmlFor="searchTypeHousekeeping"
                      >
                        {t('search:params.search.searchType.housekeeping')}
                      </label>
                    </span>
                    <span className={Style.SearchConditionFilterRequestType}>
                      <input
                        className={Style.SearchConditionFilterRequestTypeCtrl}
                        type="radio"
                        {...register('search.search_type')}
                        value="tutor"
                        id="searchTypeTutor"
                        onChange={() => changeSearchTypeHandler('tutor')}
                      />
                      <label
                        className={Style.SearchConditionFilterRequestTypeLabel}
                        htmlFor="searchTypeTutor"
                      >
                        {t('search:params.search.searchType.tutor')}
                      </label>
                    </span>
                    <span className={Style.SearchConditionFilterRequestType}>
                      <input
                        className={Style.SearchConditionFilterRequestTypeCtrl}
                        type="radio"
                        {...register('search.search_type')}
                        value="doula"
                        id="searchTypeDoula"
                        onChange={() => changeSearchTypeHandler('doula')}
                      />
                      <label
                        className={Style.SearchConditionFilterRequestTypeLabel}
                        htmlFor="searchTypeDoula"
                      >
                        {t('search:params.search.searchType.doula')}
                      </label>
                    </span>
                  </ScrollMenu>
                </div>
                <div className={Style.SearchConditionFilterReservable}>
                  <input
                    className={Style.SearchConditionFilterReservableCtrl}
                    type="checkbox"
                    {...register('search.available_schedule')}
                    id="availableSchedule"
                  />
                  <label
                    htmlFor="availableSchedule"
                    className={Style.SearchConditionFilterReservableLabel}
                  >
                    <span
                      className={Style.SearchConditionFilterReservableCheck}
                    >
                      <CheckIcon
                        className={
                          Style.SearchConditionFilterReservableCheckIcon
                        }
                      />
                    </span>
                    {t('search:params.search.availableSchedule')}
                  </label>
                </div>
                <div className={Style.SearchConditionFilterContainer}>
                  <div className={Style.SearchConditionFilterRow}>
                    <span className={Style.SearchConditionFilterRowName}>
                      エリア
                    </span>
                    <div className={Style.SearchConditionFilterRowCell}>
                      <label
                        className={Style.SearchConditionFilterArea}
                        onClick={openPrefectureHandler}
                      >
                        <span className={Style.SearchConditionFilterAreaMaker}>
                          <MakerIcon />
                        </span>
                        <span
                          className={Style.SearchConditionFilterAreaMakerText}
                        >
                          {!!selectedAreaLabel
                            ? selectedAreaLabel
                            : 'すべての地域'}
                        </span>
                        <span className={Style.SearchConditionFilterAreaNext}>
                          <ChevronRightIcon
                            className={Style.SearchConditionFilterAreaNextIcon}
                          />
                        </span>
                      </label>
                    </div>
                  </div>
                  <div className={Style.SearchConditionFilterRow}>
                    <span className={Style.SearchConditionFilterRowName}>
                      予約方法
                    </span>
                    <div className={Style.SearchConditionFilterRowCell}>
                      <div className={Style.SearchConditionFilterReserveTypes}>
                        <span
                          className={Style.SearchConditionFilterReserveType}
                        >
                          <input
                            id="reserveTypeNormal"
                            type="radio"
                            {...register('reserveType')}
                            value="normal"
                            className={
                              Style.SearchConditionFilterReserveTypeCtrl
                            }
                          />
                          <label
                            htmlFor="reserveTypeNormal"
                            className={
                              Style.SearchConditionFilterReserveTypeLabel
                            }
                          >
                            <span
                              className={
                                Style.SearchConditionFilterReserveTypeLabelCheck
                              }
                            ></span>
                            {t('search:params.search.reserveType.normal')}
                          </label>
                        </span>
                        <span
                          className={Style.SearchConditionFilterReserveType}
                        >
                          <input
                            id="reserveTypeRegular"
                            type="radio"
                            {...register('reserveType')}
                            value="regular"
                            className={
                              Style.SearchConditionFilterReserveTypeCtrl
                            }
                          />
                          <label
                            htmlFor="reserveTypeRegular"
                            className={
                              Style.SearchConditionFilterReserveTypeLabel
                            }
                          >
                            <span
                              className={
                                Style.SearchConditionFilterReserveTypeLabelCheck
                              }
                            ></span>
                            {t('search:params.search.reserveType.regular')}
                          </label>
                        </span>
                      </div>
                    </div>
                  </div>
                  <AnimatePresence>
                    {isRegularSearch(selectedReserveType) && (
                      <motion.div
                        initial={{ height: 0 }}
                        animate={{ height: 'auto' }}
                        exit={{ height: 0 }}
                        transition={{ duration: 0.3 }}
                        style={{ overflow: 'hidden' }}
                      >
                        <div className={Style.SearchConditionFilterRow}>
                          <span className={Style.SearchConditionFilterRowName}>
                            曜日指定
                          </span>
                          <div className={Style.SearchConditionFilterRowCell}>
                            <div
                              className={Style.SearchConditionFilterMultiColumn}
                            >
                              <div className={Style.SearchConditionWeeks}>
                                <label className={Style.SearchConditionWeek}>
                                  <input
                                    type="checkbox"
                                    {...register('regular_search.sunday')}
                                    value={1}
                                    className={Style.SearchConditionWeekCtrol}
                                  />
                                  <span
                                    className={Style.SearchConditionWeekLabel}
                                  >
                                    {t('search:params.regularSearch.sunday')}
                                  </span>
                                </label>
                                <label className={Style.SearchConditionWeek}>
                                  <input
                                    type="checkbox"
                                    {...register('regular_search.monday')}
                                    value={1}
                                    className={Style.SearchConditionWeekCtrol}
                                  />
                                  <span
                                    className={Style.SearchConditionWeekLabel}
                                  >
                                    {t('search:params.regularSearch.monday')}
                                  </span>
                                </label>
                                <label className={Style.SearchConditionWeek}>
                                  <input
                                    type="checkbox"
                                    {...register('regular_search.tuesday')}
                                    value={1}
                                    className={Style.SearchConditionWeekCtrol}
                                  />
                                  <span
                                    className={Style.SearchConditionWeekLabel}
                                  >
                                    {t('search:params.regularSearch.tuesday')}
                                  </span>
                                </label>
                                <label className={Style.SearchConditionWeek}>
                                  <input
                                    type="checkbox"
                                    {...register('regular_search.wednesday')}
                                    value={1}
                                    className={Style.SearchConditionWeekCtrol}
                                  />
                                  <span
                                    className={Style.SearchConditionWeekLabel}
                                  >
                                    {t('search:params.regularSearch.wednesday')}
                                  </span>
                                </label>
                                <label className={Style.SearchConditionWeek}>
                                  <input
                                    type="checkbox"
                                    {...register('regular_search.thursday')}
                                    value={1}
                                    className={Style.SearchConditionWeekCtrol}
                                  />
                                  <span
                                    className={Style.SearchConditionWeekLabel}
                                  >
                                    {t('search:params.regularSearch.thursday')}
                                  </span>
                                </label>
                                <label className={Style.SearchConditionWeek}>
                                  <input
                                    type="checkbox"
                                    {...register('regular_search.friday')}
                                    value={1}
                                    className={Style.SearchConditionWeekCtrol}
                                  />
                                  <span
                                    className={Style.SearchConditionWeekLabel}
                                  >
                                    {t('search:params.regularSearch.friday')}
                                  </span>
                                </label>
                                <label className={Style.SearchConditionWeek}>
                                  <input
                                    type="checkbox"
                                    {...register('regular_search.saturday')}
                                    value={1}
                                    className={Style.SearchConditionWeekCtrol}
                                  />
                                  <span
                                    className={Style.SearchConditionWeekLabel}
                                  >
                                    {t('search:params.regularSearch.saturday')}
                                  </span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </motion.div>
                    )}
                  </AnimatePresence>
                  {selectedSearchType === 'tutor' && (
                    <div className={Style.SearchConditionFilterRow}>
                      <span className={Style.SearchConditionFilterRowName}>
                        指導対象
                      </span>
                      <div className={Style.SearchConditionFilterRowCell}>
                        <div className={Style.SearchConditionFilterMultiColumn}>
                          <Checkbox
                            {...register('search.tutor_kindergarten')}
                            value={1}
                          >
                            {t('search:params.search.tutorKindergarten')}
                          </Checkbox>
                          <Checkbox
                            {...register('search.tutor_elementary')}
                            value={1}
                          >
                            {t('search:params.search.tutorElementary')}
                          </Checkbox>
                          <Checkbox
                            {...register('search.tutor_jrhighschool')}
                            value={1}
                          >
                            {t('search:params.search.tutorJrhighschool')}
                          </Checkbox>
                          <Checkbox
                            {...register('search.tutor_highschool')}
                            value={1}
                          >
                            {t('search:params.search.tutorHighschool')}
                          </Checkbox>
                        </div>
                      </div>
                    </div>
                  )}
                  {isSitterOrDoulaSearch(selectedSearchType) && (
                    <div className={Style.SearchConditionFilterRow}>
                      <span className={Style.SearchConditionFilterRowName}>
                        保育可能年齢
                      </span>
                      <div className={Style.SearchConditionFilterRowCell}>
                        <div className={Style.SearchConditionFilterMultiColumn}>
                          <Checkbox
                            {...register('search.nursery_age0_0')}
                            value={1}
                          >
                            {t('search:params.search.nurseryAge0-0')}
                          </Checkbox>
                          <Checkbox
                            {...register('search.nursery_age0_1to2')}
                            value={1}
                          >
                            {t('search:params.search.nurseryAge0-1to2')}
                          </Checkbox>
                          <Checkbox
                            {...register('search.nursery_age0_3to5')}
                            value={1}
                          >
                            {t('search:params.search.nurseryAge0-3to5')}
                          </Checkbox>
                          <Checkbox
                            {...register('search.nursery_age0_6to11')}
                            value={1}
                          >
                            {t('search:params.search.nurseryAge0-6to11')}
                          </Checkbox>
                          <Checkbox
                            {...register('search.nursery_age1')}
                            value={1}
                          >
                            {t('search:params.search.nurseryAge1')}
                          </Checkbox>
                          <Checkbox
                            {...register('search.nursery_age2')}
                            value={1}
                          >
                            {t('search:params.search.nurseryAge2')}
                          </Checkbox>
                          <Checkbox
                            {...register('search.nursery_age3to6')}
                            value={1}
                          >
                            {t('search:params.search.nurseryAge3to6')}
                          </Checkbox>
                          <Checkbox
                            {...register('search.nursery_age7to12')}
                            value={1}
                          >
                            {t('search:params.search.nurseryAge7to12')}
                          </Checkbox>
                        </div>
                      </div>
                    </div>
                  )}
                  {isSitterOrDoulaSearch(selectedSearchType) && (
                    <div className={Style.SearchConditionFilterRow}>
                      <span className={Style.SearchConditionFilterRowName}>
                        保育人数
                      </span>
                      <div className={Style.SearchConditionFilterRowCell}>
                        <div className={Style.SearchConditionFilterMultiColumn}>
                          <Checkbox
                            {...register('search.acceptable_number1')}
                            value={1}
                          >
                            {t('search:params.search.acceptableNumber1')}
                          </Checkbox>
                          <Checkbox
                            {...register('search.acceptable_number2')}
                            value={1}
                          >
                            {t('search:params.search.acceptableNumber2')}
                          </Checkbox>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className={Style.SearchConditionFilterRow}>
                    <span className={Style.SearchConditionFilterRowName}>
                      割引対象
                    </span>
                    <div className={Style.SearchConditionFilterRowCell}>
                      <div className={Style.SearchConditionFilterMultiColumn}>
                        {selectedSearchType === 'sitter' && (
                          <>
                            <Checkbox
                              {...register('search.cao_bs')}
                              responsive={isMobile}
                              value={1}
                            >
                              {t('search:params.search.caoBs')}
                            </Checkbox>
                            <Checkbox
                              {...register('search.tokyo_bs_tmp_use')}
                              responsive={isMobile}
                              value={1}
                            >
                              {t('search:params.search.tokyoBsTmpUse')}
                            </Checkbox>
                            <Checkbox
                              {...register('search.tokyo_bs_waiting')}
                              responsive={isMobile}
                              value={1}
                            >
                              {t('search:params.search.tokyoBsWaiting')}
                            </Checkbox>
                          </>
                        )}
                        <Checkbox
                          {...register('search.regular_discount')}
                          responsive={isMobile}
                          value={1}
                        >
                          {t('search:params.search.regularDiscount')}
                        </Checkbox>
                        <Checkbox
                          {...register('search.campaign')}
                          responsive={isMobile}
                          value={1}
                        >
                          {t('search:params.search.campaign')}
                        </Checkbox>
                        {enableHousekeepingSubsidy &&
                          selectedSearchType === 'housekeeping' && (
                            <Checkbox
                              {...register('search.housekeeping_subsidy')}
                              responsive={isMobile}
                              value={1}
                            >
                              {t('search:params.search.housekeeping_subsidy')}
                            </Checkbox>
                          )}
                      </div>
                    </div>
                  </div>
                  <div className={Style.SearchConditionFilterRow}>
                    <span className={Style.SearchConditionFilterRowName}>
                      時給
                    </span>
                    <div className={Style.SearchConditionFilterRowCell}>
                      <div className={Style.SearchConditionFilterMultiColumn}>
                        <Checkbox
                          {...register('search.hourly_wage_from_1000_to_1500')}
                          value={1}
                        >
                          {t('search:params.search.hourlyWageFrom1000To1500')}
                        </Checkbox>
                        <Checkbox
                          {...register('search.hourly_wage_from_1500_to_2000')}
                          value={1}
                        >
                          {t('search:params.search.hourlyWageFrom1500To2000')}
                        </Checkbox>
                        <Checkbox
                          {...register('search.hourly_wage_from_2000_to_2500')}
                          value={1}
                        >
                          {t('search:params.search.hourlyWageFrom2000To2500')}
                        </Checkbox>
                        <Checkbox
                          {...register('search.hourly_wage_from_2500_to_3000')}
                          value={1}
                        >
                          {t('search:params.search.hourlyWageFrom2500To3000')}
                        </Checkbox>
                        <Checkbox
                          {...register('search.hourly_wage_from_3000')}
                          value={1}
                        >
                          {t('search:params.search.hourlyWageFrom3000')}
                        </Checkbox>
                      </div>
                    </div>
                  </div>
                  {selectedSearchType === 'sitter' && (
                    <div className={Style.SearchConditionFilterRow}>
                      <span className={Style.SearchConditionFilterRowName}>
                        資格
                      </span>
                      <div className={Style.SearchConditionFilterRowCell}>
                        <div className={Style.SearchConditionFilterMultiColumn}>
                          <Checkbox
                            {...register('search.cred.childminder')}
                            value={1}
                          >
                            {t('search:params.search.cred.childminder')}
                          </Checkbox>
                          <Checkbox
                            {...register('search.cred.kindergarten_teacher')}
                            value={1}
                          >
                            {t('search:params.search.cred.kindergartenTeacher')}
                          </Checkbox>
                          <Checkbox
                            {...register('search.cred.nurse')}
                            value={1}
                          >
                            {t('search:params.search.cred.nurse')}
                          </Checkbox>
                          <Checkbox
                            {...register('search.doula_certification')}
                            value={1}
                          >
                            {t('search:params.search.doulaCertification')}
                          </Checkbox>
                        </div>
                      </div>
                    </div>
                  )}
                  {selectedSearchType === 'tutor' && (
                    <div className={Style.SearchConditionFilterRow}>
                      <span className={Style.SearchConditionFilterRowName}>
                        家庭教師
                      </span>
                      <div className={Style.SearchConditionFilterRowCell}>
                        <div className={Style.SearchConditionFilterMultiColumn}>
                          <Checkbox
                            {...register('search.tutor_tutor_supplementary')}
                            value={1}
                          >
                            {t('search:params.search.tutorTutorSupplementary')}
                          </Checkbox>
                          <Checkbox
                            {...register('search.tutor_english_bilingual')}
                            value={1}
                          >
                            {t('search:params.search.tutorEnglishBilingual')}
                          </Checkbox>
                          <Checkbox
                            {...register('search.tutor_entrance_exam')}
                            value={1}
                          >
                            {t('search:params.search.tutorEntranceExam')}
                          </Checkbox>
                          <Checkbox
                            {...register('search.tutor_tokyo_university')}
                            value={1}
                          >
                            {t('search:params.search.tutorTokyoUniversity')}
                          </Checkbox>
                          <Checkbox
                            {...register('search.tutor_keio_university')}
                            value={1}
                          >
                            {t('search:params.search.tutorKeioUniversity')}
                          </Checkbox>
                          <Checkbox
                            {...register('search.tutor_waseda_university')}
                            value={1}
                          >
                            {t('search:params.search.tutorWasedaUniversity')}
                          </Checkbox>
                          <Checkbox
                            {...register('search.tutor_sophia_university')}
                            value={1}
                          >
                            {t('search:params.search.tutorSophiaUniversity')}
                          </Checkbox>
                          <Checkbox
                            {...register('search.tutor_other_university')}
                            value={1}
                          >
                            {t('search:params.search.tutorOtherUniversity')}
                          </Checkbox>
                        </div>
                      </div>
                    </div>
                  )}
                  {selectedSearchType === 'tutor' && (
                    <div className={Style.SearchConditionFilterRow}>
                      <span className={Style.SearchConditionFilterRowName}>
                        受験
                      </span>
                      <div className={Style.SearchConditionFilterRowCell}>
                        <div className={Style.SearchConditionFilterMultiColumn}>
                          <Checkbox
                            {...register('search.tutor_elementary_exam')}
                            value={1}
                          >
                            {t('search:params.search.tutorElementaryExam')}
                          </Checkbox>
                          <Checkbox
                            {...register('search.tutor_jrhighschool_exam')}
                            value={1}
                          >
                            {t('search:params.search.tutorJrhighschoolExam')}
                          </Checkbox>
                          <Checkbox
                            {...register('search.tutor_highschool_exam')}
                            value={1}
                          >
                            {t('search:params.search.tutorHighschoolExam')}
                          </Checkbox>
                          <Checkbox
                            {...register('search.tutor_college_exam')}
                            value={1}
                          >
                            {t('search:params.search.tutorCollegeExam')}
                          </Checkbox>
                          <Checkbox
                            {...register('search.tutor_elementary_exam_ex')}
                            value={1}
                          >
                            {t('search:params.search.tutorElementaryExamEx')}
                          </Checkbox>
                          <Checkbox
                            {...register('search.tutor_jrhighschool_exam_ex')}
                            value={1}
                          >
                            {t('search:params.search.tutorJrhighschoolExamEx')}
                          </Checkbox>
                          <Checkbox
                            {...register('search.tutor_highschool_exam_ex')}
                            value={1}
                          >
                            {t('search:params.search.tutorHighschoolExamEx')}
                          </Checkbox>
                          <Checkbox
                            {...register('search.tutor_college_exam_ex')}
                            value={1}
                          >
                            {t('search:params.search.tutorCollegeExamEx')}
                          </Checkbox>
                        </div>
                      </div>
                    </div>
                  )}

                  {selectedSearchType === 'tutor' && (
                    <div className={Style.SearchConditionFilterRow}>
                      <span className={Style.SearchConditionFilterRowName}>
                        科目
                      </span>
                      <div className={Style.SearchConditionFilterRowCell}>
                        <div className={Style.SearchConditionFilterMultiColumn}>
                          <Checkbox
                            {...register('search.tutor_national_language')}
                            value={1}
                          >
                            {t('search:params.search.tutorNationalLanguage')}
                          </Checkbox>
                          <Checkbox
                            {...register('search.tutor_arithmetic')}
                            value={1}
                          >
                            {t('search:params.search.tutorArithmetic')}
                          </Checkbox>
                          <Checkbox
                            {...register('search.tutor_science')}
                            value={1}
                          >
                            {t('search:params.search.tutorScience')}
                          </Checkbox>
                          <Checkbox
                            {...register('search.tutor_social_studies')}
                            value={1}
                          >
                            {t('search:params.search.tutorSocialStudies')}
                          </Checkbox>
                          <Checkbox
                            {...register('search.tutor_english')}
                            value={1}
                          >
                            {t('search:params.search.tutorEnglish')}
                          </Checkbox>
                          <Checkbox
                            {...register('search.tutor_english_speaking')}
                            value={1}
                          >
                            {t('search:params.search.tutorEnglishSpeaking')}
                          </Checkbox>
                          <Checkbox
                            {...register('search.tutor_toeic')}
                            value={1}
                          >
                            {t('search:params.search.tutorToeic')}
                          </Checkbox>
                          <Checkbox
                            {...register('search.tutor_toefl')}
                            value={1}
                          >
                            {t('search:params.search.tutorToefl')}
                          </Checkbox>
                          <Checkbox
                            {...register('search.tutor_english_exam')}
                            value={1}
                          >
                            {t('search:params.search.tutorEnglishExam')}
                          </Checkbox>
                          <Checkbox
                            {...register('search.tutor_math')}
                            value={1}
                          >
                            {t('search:params.search.tutorMath')}
                          </Checkbox>
                          <Checkbox
                            {...register('search.tutor_ancient_writing')}
                            value={1}
                          >
                            {t('search:params.search.tutorAncientWriting')}
                          </Checkbox>
                          <Checkbox
                            {...register('search.tutor_chinese_classics')}
                            value={1}
                          >
                            {t('search:params.search.tutorChineseClassics')}
                          </Checkbox>
                          <Checkbox
                            {...register('search.tutor_essay')}
                            value={1}
                          >
                            {t('search:params.search.tutorEssay')}
                          </Checkbox>
                          <Checkbox
                            {...register('search.tutor_physics')}
                            value={1}
                          >
                            {t('search:params.search.tutorPhysics')}
                          </Checkbox>
                          <Checkbox
                            {...register('search.tutor_chemistry')}
                            value={1}
                          >
                            {t('search:params.search.tutorChemistry')}
                          </Checkbox>
                          <Checkbox
                            {...register('search.tutor_biology')}
                            value={1}
                          >
                            {t('search:params.search.tutorBiology')}
                          </Checkbox>
                          <Checkbox
                            {...register('search.tutor_geoscience')}
                            value={1}
                          >
                            {t('search:params.search.tutorGeoscience')}
                          </Checkbox>
                          <Checkbox
                            {...register('search.tutor_japanese_history')}
                            value={1}
                          >
                            {t('search:params.search.tutorJapaneseHistory')}
                          </Checkbox>
                          <Checkbox
                            {...register('search.tutor_world_history')}
                            value={1}
                          >
                            {t('search:params.search.tutorWorldHistory')}
                          </Checkbox>
                          <Checkbox
                            {...register('search.tutor_geography')}
                            value={1}
                          >
                            {t('search:params.search.tutorGeography')}
                          </Checkbox>
                          <Checkbox
                            {...register('search.tutor_ethics')}
                            value={1}
                          >
                            {t('search:params.search.tutorEthics')}
                          </Checkbox>
                          <Checkbox
                            {...register('search.tutor_politics_economics')}
                            value={1}
                          >
                            {t('search:params.search.tutorPoliticsEconomics')}
                          </Checkbox>
                        </div>
                      </div>
                    </div>
                  )}
                  {selectedSearchType !== 'doula' && (
                    <div className={Style.SearchConditionFilterRow}>
                      <span className={Style.SearchConditionFilterRowName}>
                        サポート内容
                      </span>
                      <div className={Style.SearchConditionFilterRowCell}>
                        <div className={Style.SearchConditionFilterMultiColumn}>
                          {selectedSearchType === 'sitter' && (
                            <>
                              <Checkbox
                                {...register('search.pickup_only_support')}
                                value={1}
                              >
                                {t('search:params.search.pickupOnlySupport')}
                              </Checkbox>
                              <Checkbox
                                {...register('search.mid_night_support')}
                                value={1}
                              >
                                {t('search:params.search.midNightSupport')}
                              </Checkbox>
                              <Checkbox
                                {...register('search.handicap_support')}
                                value={1}
                              >
                                {t('search:params.search.handicapSupport')}
                              </Checkbox>
                              <Checkbox
                                {...register('search.tutor_enable_flag')}
                                value={1}
                              >
                                {t('search:params.search.tutorEnableFlag')}
                              </Checkbox>
                              <Checkbox
                                {...register('search.english_lesson_support')}
                                value={1}
                              >
                                {t('search:params.search.englishLessonSupport')}
                              </Checkbox>
                              <Checkbox
                                {...register('search.music_lesson_support')}
                                value={1}
                              >
                                {t('search:params.search.musicLessonSupport')}
                              </Checkbox>
                              <Checkbox
                                {...register('search.sports_lesson_support')}
                                value={1}
                              >
                                {t('search:params.search.sportsLessonSupport')}
                              </Checkbox>
                              <Checkbox
                                {...register('search.art_lesson_support')}
                                value={1}
                              >
                                {t('search:params.search.artLessonSupport')}
                              </Checkbox>
                              <Checkbox
                                {...register('search.other_appeal_support')}
                                value={1}
                              >
                                {t('search:params.search.otherAppealSupport')}
                              </Checkbox>
                            </>
                          )}
                          {selectedSearchType === 'housekeeping' && (
                            <>
                              <Checkbox
                                {...register(
                                  'search.housekeeping_clean_up_support',
                                )}
                                value={1}
                              >
                                {t(
                                  'search:params.search.housekeepingCleanUpSupport',
                                )}
                              </Checkbox>
                              <Checkbox
                                {...register(
                                  'search.housekeeping_tidy_up_support',
                                )}
                                value={1}
                              >
                                {t(
                                  'search:params.search.housekeepingTidyUpSupport',
                                )}
                              </Checkbox>
                              <Checkbox
                                {...register(
                                  'search.housekeeping_home_cooking_support',
                                )}
                                value={1}
                              >
                                {t(
                                  'search:params.search.housekeepingHomeCookingSupport',
                                )}
                              </Checkbox>

                              <Checkbox
                                {...register('search.housekeeping_shopping')}
                                value={1}
                                tooltipText={
                                  <>
                                    買い物代行は「家事サポートに必要な買い物」のみが対象です。
                                    例：サポート時間内に食材の買い出し～調理までを希望
                                    詳しくは
                                    <a
                                      style={{ textDecoration: 'underline' }}
                                      href="/help/center_detail/parent_housekeeping_shopping"
                                    >
                                      こちら
                                    </a>
                                  </>
                                }
                                wrapperRight={wrapperRight}
                              >
                                {t('search:params.search.housekeepingShopping')}
                              </Checkbox>
                              <Checkbox
                                {...register(
                                  'search.housekeeping_cooking_in_advance_support',
                                )}
                                value={1}
                              >
                                {t(
                                  'search:params.search.housekeepingCookingInAdvanceSupport',
                                )}
                              </Checkbox>
                              <Checkbox
                                {...register(
                                  'search.housekeeping_home_party_cooking',
                                )}
                                value={1}
                              >
                                {t(
                                  'search:params.search.housekeepingHomePartyCooking',
                                )}
                              </Checkbox>
                            </>
                          )}
                          {selectedSearchType === 'tutor' && (
                            <>
                              <Checkbox
                                {...register('search.tutor_enable_flag')}
                                value={1}
                              >
                                {t('search:params.search.tutorEnableFlag')}
                              </Checkbox>
                              <Checkbox
                                {...register('search.english_lesson_support')}
                                value={1}
                              >
                                {t('search:params.search.englishLessonSupport')}
                              </Checkbox>
                              <Checkbox
                                {...register('search.music_lesson_support')}
                                value={1}
                              >
                                {t('search:params.search.musicLessonSupport')}
                              </Checkbox>
                              <Checkbox
                                {...register('search.sports_lesson_support')}
                                value={1}
                              >
                                {t('search:params.search.sportsLessonSupport')}
                              </Checkbox>
                              <Checkbox
                                {...register('search.art_lesson_support')}
                                value={1}
                              >
                                {t('search:params.search.artLessonSupport')}
                              </Checkbox>
                              <Checkbox
                                {...register('search.other_appeal_support')}
                                value={1}
                              >
                                {t('search:params.search.otherAppealSupport')}
                              </Checkbox>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                  {selectedSearchType === 'doula' && (
                    <div className={Style.SearchConditionFilterRow}>
                      <span className={Style.SearchConditionFilterRowName}>
                        資格
                      </span>
                      <div className={Style.SearchConditionFilterRowCell}>
                        <div className={Style.SearchConditionFilterMultiColumn}>
                          <Checkbox
                            {...register('search.doula_certification_official')}
                            value={1}
                          >
                            {t(
                              'search:params.search.doulaCertificationOfficial',
                            )}
                          </Checkbox>
                          <Checkbox
                            {...register('search.nursing_doula')}
                            value={1}
                          >
                            {t('search:params.search.nursingDoula')}
                          </Checkbox>
                          <Checkbox
                            {...register('search.doulaedu_certification')}
                            value={1}
                          >
                            {t('search:params.search.doulaeduCertification')}
                          </Checkbox>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className={Style.SearchConditionFilterRow}>
                    <span className={Style.SearchConditionFilterRowName}>
                      対応時間帯
                    </span>
                    <div className={Style.SearchConditionFilterRowCell}>
                      <div className={Style.SearchConditionFilterMultiColumn}>
                        <div
                          className={Style.SearchConditionFilterTwoColumnItem}
                        >
                          <Checkbox
                            {...register('search.morning_support')}
                            responsive={isMobile}
                            value={1}
                          >
                            {t('search:params.search.morningSupport')}
                          </Checkbox>
                        </div>
                        {selectedSearchType !== 'housekeeping' &&
                          selectedSearchType !== 'tutor' && (
                            <div
                              className={
                                Style.SearchConditionFilterTwoColumnItem
                              }
                            >
                              <Checkbox
                                {...register('search.night_support')}
                                responsive={isMobile}
                                value={1}
                              >
                                {t('search:params.search.nightSupport')}
                              </Checkbox>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                  {selectedSearchType === 'sitter' && (
                    <div className={Style.SearchConditionFilterRow}>
                      <span className={Style.SearchConditionFilterRowName}>
                        病児
                      </span>
                      <div className={Style.SearchConditionFilterRowCell}>
                        <div className={Style.SearchConditionFilterMultiColumn}>
                          <Checkbox
                            {...register('search.sick_bsl_fever')}
                            value={1}
                          >
                            {t('search:params.search.sickBslFever')}
                          </Checkbox>
                          <Checkbox
                            {...register('search.sick_bsl_unknown_disease')}
                            value={1}
                          >
                            {t('search:params.search.sickBslUnknownDisease')}
                          </Checkbox>
                          <Checkbox
                            {...register('search.sick_bsl_infection')}
                            value={1}
                          >
                            {t('search:params.search.sickBslInfection')}
                          </Checkbox>
                          <Checkbox
                            {...register('search.sick_bsl_unknown')}
                            value={1}
                          >
                            {t('search:params.search.sickBslUnknown')}
                          </Checkbox>
                        </div>
                      </div>
                    </div>
                  )}
                  {selectedSearchType === 'sitter' && (
                    <div className={Style.SearchConditionFilterRow}>
                      <span className={Style.SearchConditionFilterRowName}>
                        病後児
                      </span>
                      <div className={Style.SearchConditionFilterRowCell}>
                        <div className={Style.SearchConditionFilterMultiColumn}>
                          <Checkbox
                            {...register('search.illness_bsl_fever')}
                            value={1}
                          >
                            {t('search:params.search.illnessBslFever')}
                          </Checkbox>
                          <Checkbox
                            {...register('search.illness_bsl_unknown_disease')}
                            value={1}
                          >
                            {t('search:params.search.illnessBslUnknownDisease')}
                          </Checkbox>
                          <Checkbox
                            {...register('search.illness_bsl_infection')}
                            value={1}
                          >
                            {t('search:params.search.illnessBslInfection')}
                          </Checkbox>
                        </div>
                      </div>
                    </div>
                  )}
                  {selectedSearchType !== 'tutor' && (
                    <div className={Style.SearchConditionFilterRow}>
                      <span className={Style.SearchConditionFilterRowName}>
                        特徴
                      </span>
                      <div className={Style.SearchConditionFilterRowCell}>
                        <div className={Style.SearchConditionFilterMultiColumn}>
                          {selectedSearchType === 'doula' && (
                            <>
                              <Checkbox
                                {...register('search.no_option')}
                                value={1}
                              >
                                {t('search:params.search.noOption')}
                              </Checkbox>
                              <Checkbox
                                {...register('search.foreign_language_support')}
                                value={1}
                              >
                                {t(
                                  'search:params.search.foreignLanguageSupport',
                                )}
                              </Checkbox>
                              <Checkbox
                                {...register('search.pickup_only_support')}
                                value={1}
                              >
                                {t('search:params.search.pickupOnlySupport')}
                              </Checkbox>
                              <Checkbox
                                {...register('search.handicap_support')}
                                value={1}
                              >
                                {t('search:params.search.handicapSupport')}
                              </Checkbox>
                            </>
                          )}
                          <Checkbox
                            {...register('search.new_debuted_sitter')}
                            value={1}
                            tooltipText={
                              selectedSearchType === 'housekeeping'
                                ? 'デビューサポーターとは、家事サポート完了件数が5回未満のサポーターとなります。'
                                : 'デビューサポーターとは、ベビーシッターサポート完了件数が5回未満のサポーターとなります。'
                            }
                            wrapperRight={wrapperRight}
                          >
                            {t('search:params.search.newDebutedSitter')}
                          </Checkbox>
                          {selectedSearchType === 'housekeeping' && (
                            <>
                              <Checkbox
                                {...register(
                                  'search.housekeeping_clean_with_cooking',
                                )}
                                value={1}
                              >
                                {t(
                                  'search:params.search.housekeepingCleanWithCooking',
                                )}
                              </Checkbox>
                              <Checkbox
                                {...register(
                                  'search.housekeeping_cooking_for_food_industry',
                                )}
                                value={1}
                              >
                                {t(
                                  'search:params.search.housekeepingCookingForFoodIndustry',
                                )}
                              </Checkbox>
                            </>
                          )}
                          {selectedSearchType !== 'housekeeping' &&
                            selectedSearchType !== 'doula' && (
                              <Checkbox
                                {...register('search.foreign_language_support')}
                                value={1}
                              >
                                {t(
                                  'search:params.search.foreignLanguageSupport',
                                )}
                              </Checkbox>
                            )}
                          {selectedSearchType !== 'housekeeping' && (
                            <Checkbox
                              {...register(
                                'search.semi_childcare_certification',
                              )}
                              value={1}
                            >
                              {t(
                                'search:params.search.semiChildcareCertification',
                              )}
                            </Checkbox>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  <div className={Style.SearchConditionFilterRow}>
                    <span className={Style.SearchConditionFilterRowName}>
                      キーワードで絞る
                    </span>
                    <div className={Style.SearchConditionFilterRowCell}>
                      <div className={Style.SearchConditionFilterMultiColumn}>
                        <div className={Style.SearchConditionFilterWord}>
                          <InputText {...register('search.word')} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={Style.SearchConditionFilterActions}>
                  <a
                    className={Style.SearchConditionFilterActionSecondaly}
                    onClick={resetHandler}
                  >
                    リセット
                  </a>
                  <a
                    className={Style.SearchConditionFilterActionPrimary}
                    onClick={submitHandler}
                  >
                    {submitLabel}
                  </a>
                </div>
              </div>
            </>
          )}
        </swiper-slide>
        <swiper-slide>
          <SubmitSwiper
            eventPrefix="submitSwiper-"
            buttonText="決定"
            setSwiper={setSubmitSwiper}
            showSubmitButton={showSubmitButton}
            submitHandler={submitSearchTopHandler}
          >
            <swiper-slide>
              {prefectureModalDisplay && (
                <SearchConditionPrefecture
                  prefectures={prefectures}
                  selectedGeocodes={selectedGeocodes}
                  selectedStations={selectedStations}
                  selectPrefectureHandler={openAreaSelectHandler}
                  prevHandler={closePrefectureHandler}
                  resetHandler={resetPrefectureHandler}
                />
              )}
              <AnimatePresence>
                {resetMessage && (
                  <motion.div
                    initial={{ opacity: 0, y: '-10%' }}
                    animate={{ opacity: 1, y: '0%' }}
                    exit={{ opacity: 0, y: '-10%' }}
                    className={Style.ResetMessage}
                  >
                    すべての市区町村、主要駅の
                    <br />
                    指定をリセットしました
                  </motion.div>
                )}
              </AnimatePresence>
            </swiper-slide>
            <swiper-slide>
              {currentPrefecture && (
                <SearchConditionArea
                  currentPrefecture={currentPrefecture}
                  getValues={getValues}
                  setValue={setValue}
                  prevHandler={closeAreaSelectHandler}
                />
              )}
            </swiper-slide>
          </SubmitSwiper>
        </swiper-slide>
      </swiper-container>
    </div>
  )
}
